import * as lan from '../common/script/common_lan.js';

import React, {useState, useEffect} from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as common from '../common/script/common.js';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import axios from 'axios';
import stringify from 'node-stringify';
import { withRouter } from 'react-router-dom';
import $ from 'jquery';

import BottomRecord from '../popup/bottom_record';
import * as slide from '../popup/bottom_record';
var data, temphtmllist = [];

export default function Record() {
	const location = useLocation();
	const navigate = useNavigate();
	
	var datalist;
	var gameType, isTournamentView;
	var gameTypeVal = '';
	var gameTypeLink = '';
	var senddata = {
		page:1,
		pagesize:50
	};

	if(location.state === undefined || location.state == null)
	{
		gameType = lan.L(Object.values(common.commonKeyDic['gameType'])[0]);
	}
	else
	{
		gameType = location.state.gameType;
		isTournamentView = location.state.isTournamentView;
		gameTypeVal = common.commonKeyDic['gameTypeVal'][common.getCommonKeyByValue(lan.LTarget(gameType,'ko'), 'gameType')];

		if(gameTypeVal != '') senddata = Object.assign(senddata,{gameType : gameTypeVal});
		if(isTournamentView != '') senddata = Object.assign(senddata,{isTournamentView : isTournamentView});
	}

	const [htmllist, sethtmllist] = useState([]);
	var html;

	//화면전환시 실행
	useEffect(() => {
		
		var wrap = $(".contents");
		var headeroffset = $("header").offset();
		//wrap.css("margin-top","0");

		temphtmllist = [];
		html = [];

		const scrollHandler = () => {

			if($(window).scrollTop() + $(window).height() >= $(document).height() - 30) {
				if(senddata.page < data.maxPage)
				{
					senddata.page += 1;
					goData();
				}
			}
		  }
			  
		  window.addEventListener("scroll", scrollHandler);
		  

		$(window).scroll(function () {
			if ($(this).scrollTop() > headeroffset.top){
				$(".search_standard").addClass("fixed");
				wrap.css("margin-top","50px");
			}else {
				$(".search_standard").removeClass("fixed");
				wrap.css("margin-top","0");
			}
		});

		goData();

		  // Return effect cleanup function to remove listener
		  return () => {
			window.removeEventListener("scroll", scrollHandler);
		  };
    },[location]);
	
	const goData = () => {

		$(".nodata").hide();

		if(gameTypeVal == '' || gameTypeVal === undefined) gameTypeLink = 'gameType=1&gameType=2'

			axios({
			method: 'post',
			url: process.env.REACT_APP_SERVER + '/api',
			data: senddata,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'language':sessionStorage.getItem('Language'),
				'pUrl' : encodeURIComponent('나의 기록실 목록 조회 라운드'),
				'guid' : sessionStorage.getItem('guid'),
				'token' : sessionStorage.getItem('access_token'),
				'getplus' : gameTypeLink
			}
		}).then(function (response) {
			console.log(response.data);

			if(response.data.error !== undefined && response.data.error.code > 0)
			{
				common.alertByError('나의 기록실',response.data.error.code);
				return false;
			}
			else
			{
				var count = 0;
				datalist = response.data.list;
				data = response.data;
				html = [];
				for (let i = 0;i < datalist.length; i++)
				{
					if(datalist[i].shopName == null) datalist[i].shopName = 'null';
					if(datalist[i].courseName == null) datalist[i].courseName = 'null';

					var gametypeclass = common.getCommonKeyByValue(lan.LTarget(datalist[i].gameType,'ko'),'gameType');

					html.push(
						<a onClick={(e) => goDetail(e, i ,common.getCommonKeyByValue(lan.LTarget(datalist[i].gameType,'ko'),'gameType'))}>
						  <p style={{backgroundImage: "url(\'"+response.data.cdn+datalist[i].logo+"\')"}}>image</p>
						  <div className="record_info">
							<span>
							  <div className={"game_type match"} style={datalist[i].isTournament ? {}: {display:'none'}}>{lan.L('대회')}</div>&nbsp;
							  <div className={"game_type "+gametypeclass}>{lan.L(datalist[i].gameType)}</div>
							</span>
							<em>{datalist[i].courseName}</em>
							<strong>{datalist[i].shopName}</strong>
							<sub>{common.UnixTime(datalist[i].startTime,'년.월.일')}</sub>
						  </div>
						  <div className="score_point">
							<b>{datalist[i].score}</b>
							<small>{lan.L('score')}</small>
						  </div>
						</a>
					)
					count = count + 1;
				}
				$(".nodata").hide();
				html = [...temphtmllist,...html];
				sethtmllist(html);
				temphtmllist = html;
			}
			
			if(count == 0)
			{
				$(".nodata").show();
			}
		}).catch(error => {
			console.log(error.response)
		});
	}
	
	const goSelect = (e) => {
		var value = e.target.value;
		$(window).scrollTop(0);
		navigate('/record/record', {state : {gameType : gameType , isTournamentView : value}, replace : true});
	}
	
	const goDetail = (e, i, gametypeclass) => {
		var id = e.target.id;
		if(gametypeclass === 'stroke')
		{
			navigate('/record/detail_stroke', {state : {roundData : datalist[i]}});
		} else if(gametypeclass === 'foursome')
		{
			navigate('/record/detail_foursome', {state : {roundData : datalist[i]}});
		} else if(gametypeclass === 'scramble')
		{
			navigate('/record/detail_scramble', {state : {roundData : datalist[i]}});
		}
	}

	return (
	<>
	<BottomRecord to="/record/record" isTournamentView={isTournamentView} gameType={gameType}></BottomRecord>
	<div className="contents">
	  <div className="search_standard">
		<span onClick={slide.slideOn}><label className="filter_index" >{gameType==lan.L(common.commonKeyDic['gameType']['All']) ? lan.L('경기방식 전체') : gameType}</label></span>
		<div className="direct_check">
		  <a>
			<input type="radio" id="status10" name="status1" value="" onClick={goSelect} defaultChecked />
			<label htmlFor="status10">{lan.L("전체")}</label>
		  </a>
		  <a>
			<input type="radio" id="status11" name="status1" value={false} onClick={goSelect}/>
			<label htmlFor="status11">{lan.L("일반")}</label>
		  </a>
		  <a>
			<input type="radio" id="status12" name="status1" value={true} onClick={goSelect}/>
			<label htmlFor="status12">{lan.L("대회")}</label>
		  </a>
		</div>
	  </div>
	  {/* No Data */}
		<div className="nodata">
		<p><img src={require("../images/character/nodata_4.png")} /></p>
		<strong>{lan.L("라운드 기록이 없어요.")}</strong>
		<span>{lan.L("가까운 매장에서 플레이 해보세요.")}</span>
		</div>
	  {/*//No Data */}
	  <div className="list_record">
		{htmllist}
	  </div>
	</div>
	</>
	);
}