import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import * as lan from '../common/script/common_lan.js';
import axios from 'axios';
import { showAlert } from '../common/script/common';

export default function Payment(){
	const location = useLocation();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);
	const [isReady, setIsReady] = useState(false);
	const [infoItems, setInfoItems] = useState([]);

	const loadExternalScript = (src, id) => {
		return new Promise((resolve, reject) => {
			if (document.getElementById(id)) return resolve();
			const script = document.createElement('script');
			script.src = src;
			script.async = true;
			script.id = id;
			script.onload = resolve;
			script.onerror = reject;
			document.head.appendChild(script);
		});
	};

	useEffect(() => {
		const fetchAndPrepareScript = async () => {
			try {
				const queryParams = new URLSearchParams(location.search);

				const clientGuid = queryParams.get("clientGUID");
				const gameType = queryParams.get("gameType");
				const confirmKey = queryParams.get("confirmkey");
				const gameHoleType = queryParams.get("gameHoleType");
				const playerNumber = queryParams.get("playerNumber");
				const coursePlanId = queryParams.get("coursePlanId");

				if (!clientGuid || !gameType || !confirmKey || !gameHoleType || !playerNumber || !coursePlanId) {
					throw new Error('Missing required query parameters');
				}

				// ✅ gameType 텍스트 변환
				const gameTypeText = gameType === "0" ? "스트로크"
					: gameType === "1" ? "포썸"
						: gameType === "2" ? "3vs3"
							: gameType === "3" ? "2vs2"
				              : "2vs2vs2";

				// ✅ gameHoleType 텍스트 변환
				const gameHoleTypeText = gameHoleType === "0" ? "18홀 - 전반홀 > 후반홀"
					: gameHoleType === "1" ? "18홀 - 후반홀 > 전반홀"
						: gameHoleType === "2" ? "9홀 - 전반홀, 후반홀"
							: "9홀 - 후반홀, 전반홀";

				// ✅ playerNumber 텍스트 변환
				const playerNumberText = `${playerNumber}명`;

				setInfoItems([
					{ title: '게임 타입', content: gameTypeText },
					{ title: '홀 타입', content: gameHoleTypeText },
					{ title: '인원수', content: playerNumberText }
				]);

				// test
				
				// const testPayload = {
				// 	paymentMethodType: "CreditCard",
				// 	clientGuid: clientGuid,
				// 	quantity: 1,
				// 	totalPrice: 110
				// };
				//
				// const res = await axios.post('https://qa-billing.thekgolf.com/v2/payment/domestic', testPayload, {
				// 	headers: {
				// 		'Content-Type': 'application/json',
				// 		'x-thekgolf-timezone': '+09:00',
				// 		'x-thekgolf-language': 'ko-kr',
				// 		'Authorization': 'eyJhbGciOiJSUzI1NiIsImtpZCI6IkVFOEMzRDM5RkExM0ExOUQxNzIwQjBFNDYzMzc1NzhFRTYxNkQ5NzQiLCJ0eXAiOiJhdCtqd3QiLCJ4NXQiOiI3b3c5T2ZvVG9aMFhJTERrWXpkWGp1WVcyWFEifQ.eyJuYmYiOjE3NDM2NDU0MTEsImV4cCI6MTc0MzczMTgxMSwiaXNzIjoiaHR0cHM6Ly9xYS1hdXRoLnRoZWtnb2xmLmNvbSIsImF1ZCI6IlNob3BfQXV0aCIsImNsaWVudF9pZCI6InRoZWtnb2xmX3Nob3BfcWEiLCJzdWIiOiIxIiwiYXV0aF90aW1lIjoxNzQzNjQ1NDExLCJpZHAiOiJUaGVLR29sZl9TaG9wIiwicm9sZSI6IlNob3BNYW5hZ2VyIiwiaWR4IjoiMSIsIm1hbmFnZXJJZCI6ImdzYml6MSIsIm1hbmFnZXJOYW1lIjoiR1NCSVoiLCJtYW5hZ2VyVHlwZSI6Im1hbmFnZXJfdHlwZV9jZDphZG1pbiIsInNob3BHdWlkIjoiODBmZCIsInNob3BOYW1lIjoiR1NCSVoxIiwiY291bnRyeSI6IktSIiwiaXNTaG9wT3duZXIiOiJ0cnVlIiwic2NvcGUiOlsib3BlbmlkIiwiU2hvcF9BdXRoIiwib2ZmbGluZV9hY2Nlc3MiXSwiYW1yIjpbImN1c3RvbSJdfQ.HNpp-D6XJe9dtycvrWMDtGc5VJAolE-HC9wtfqZg-ZODY6m8MkDn0hdxCKRtV5bJ3csyMgGC9dTbAcFt7-SmiFIrhDN-4ge20JbMMnP0Q0j7MN5jA08KboWi34FGE0vPH0C7tri1VNT-01YCQzdTkbH81d37iZ2RzAeqyUfdw7JngqDZSb2idQETq62FizoIdsAzD0XI6FgG50mdfgjF7Ee9LMXP72axiSWKS9V9pmgfhZ7Ly1C7m-sJNgADUXkIOjqvftXJSw93xhpmHZuiMPXbYmP0L-f1Je-7kgrs0hfyzJF5f5bTg317oRsH_pFpP5EL_xgqlV8ds1vSyNa6SQ' // Replace with actual token
				// 	}
				// });

				const url = `https://qa-billing.thekgolf.com/v2/payment/domestic/course?clientGuid=${clientGuid}&gameType=${gameType}&confirmkey=${confirmKey}&gameHoleType=${gameHoleType}&playerNumber=${playerNumber}&coursePlanId=${coursePlanId}`;

				const res = await axios.post(url, {}, {
					headers: {
						'Content-Type': 'application/json',
						'x-thekgolf-timezone': '+09:00',
						'x-thekgolf-language': 'ko-kr'
					}
				});

				console.log("Server response:", res.data);

				if (res.data && typeof res.data.script === 'string') {
					const tempDiv = document.createElement('div');
					tempDiv.innerHTML = res.data.script;

					const embeddedScript = tempDiv.querySelector('script');
					if (embeddedScript) {
						const execScript = document.createElement('script');
						execScript.text = embeddedScript.innerText;
						document.body.appendChild(execScript);
					}

					if (res.data.html) {
						document.getElementById('payment-container').innerHTML = res.data.html;
					}

					await loadExternalScript(process.env.REACT_APP_SETTLEBANK_SCRIPT, "settlebank-core-script");

					setIsReady(true);
				} else {
					throw new Error('Response is not a valid script');
				}
			} catch (error) {
				console.error(error);
				showAlert(lan.L('Payment initialization failed'));
				navigate('/error');
			} finally {
				setLoading(false);
			}
		};

		fetchAndPrepareScript();

	}, [location, navigate]);

	const handleStartPayment = () => {
		if (typeof window.fnCreditCardPaymentRequest === 'function') {
			window.fnCreditCardPaymentRequest();
		} else {
			console.warn("fnCreditCardPaymentRequest is not defined");
		}
	};

	return (
		<>
			<div class="login_head">
				<span></span>
			</div>
			<div id="payment-container" style={{ textAlign: 'center' }}></div>
			<div style={{
				backgroundImage: "url(" + require('../images/bg.png') + ")",
				backgroundSize: 'cover',
				backgroundPosition: 'center',
				height: 'calc(100vh - 90px)',
				display: 'flex',
				flexDirection: 'column',
				color: '#fff',
				textAlign: 'center',
				padding: '1.5rem',
				marginTop : '0.8rem'
			}}>
				{/* 상단 공간 (여유 공간) */}
				<div style={{ flex: 1 }}></div>

				{/* 표 영역 */}
				{isReady && (
					<div style={{
						backgroundColor: 'rgba(0, 0, 0, 0.4)',
						borderRadius: '8px',
						width: '90%',
						maxWidth: '320px',
						boxSizing: 'border-box',
						display: 'flex',
						flexDirection: 'column',
						margin: '0 auto'
					}}>
						{infoItems.map((item, index) => (
							<div key={index} style={{
								display: 'flex',
								borderTop: index !== 0 ? '1px solid rgba(255, 255, 255, 0.5)' : 'none'
							}}>
								<div style={{
									flex: 1,
									borderRight: '1px solid rgba(255, 255, 255, 0.5)',
									padding: '0.75rem',
									color: '#fff',
									fontSize: '0.9rem',
									textAlign: 'left',
									whiteSpace: 'pre-line'
								}}>
									{item.title}
								</div>
								<div style={{
									flex: 2,
									padding: '0.75rem',
									color: '#fff',
									fontSize: '0.9rem',
									textAlign: 'right',
									whiteSpace: 'pre-line'
								}}>
									{item.content}
								</div>
							</div>
						))}
					</div>
				)}

				{/* 버튼 영역 */}
				{isReady && (
					<div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
						<button
							onClick={handleStartPayment}
							disabled={loading}
							style={{
								backgroundColor: '#1D4ED8',
								color: '#ffffff',
								fontSize: '1.125rem',
								padding: '0.75rem 2rem',
								border: 'none',
								borderRadius: '9999px',
								boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
								cursor: 'pointer'
							}}
						>
							코스 결제 진행
						</button>
					</div>
				)}
				<p>경고 문구 경고 문구 경고 문구 경고 문구 경고 문구 경고 문구</p>
				{/* 하단 공간 (여유 공간) */}
				<div style={{ flex: 0.4 }}></div>
			</div>
		</>
	);

}

// 👉 라우트 주소는 다음과 같이 설정되어야 합니다:
// http://localhost:3000/#/pay/course?itemId=10111011011012
// http://localhost:3000/#/pay/course?clientGUID=80fd1234&gameType=0&confirmkey=0123[%E2%80%A6]ccdddeeefff&gameHoleType=2&playerNumber=1&coursePlanId=104